<template>
  <paginate
    v-if="totalPage > 1"
    :value="currentPage"
    :pageCount="totalPage"
    :page-range="pageRange"
    :clickHandler="changePage"
    :prev-text='`<svg xmlns="http://www.w3.org/2000/svg" width="7.049" height="12.305" viewBox="0 0 7.049 12.305">
                    <path id="chevron_right" data-name="chevron right" d="M.268,5.524,5.544.247a.9.9,0,0,1,1.237,0,.847.847,0,0,1,0,1.237L2.123,6.142,6.781,10.8a.847.847,0,0,1,0,1.237,1,1,0,0,1-.278.206.966.966,0,0,1-.68,0,1,1,0,0,1-.278-.206L.268,6.76a.847.847,0,0,1,0-1.237Z" fill="#c66c44"/>
                 </svg>`'
    :next-text='`<svg xmlns="http://www.w3.org/2000/svg" width="6.874" height="12" viewBox="0 0 6.874 12">
                    <path id="chevron_right" data-name="chevron right" d="M6.613,5.387,1.467.241A.863.863,0,0,0,.864,0a.863.863,0,0,0-.6.241.826.826,0,0,0,0,1.206L4.8,5.99.261,10.533a.826.826,0,0,0,0,1.206.973.973,0,0,0,.271.2A.793.793,0,0,0,.864,12a.793.793,0,0,0,.332-.06.973.973,0,0,0,.271-.2L6.613,6.593a.826.826,0,0,0,0-1.206Z" fill="#c66c44"/>
                  </svg>`'
    :containerClass="'pagination-common'">
  </paginate>
</template>

<script>
export default {
  name: 'Pagination',

  props: {
    currentPage: {
      type: Number,
      default: 1
    },

    totalPage: {
      type: Number,
      default: 5
    },
    pageRange: {
      type: Number,
      default: 4
    }
  },

  methods: {
    changePage (pageNum) {
      this.$emit('change-page', pageNum)
    }
  }
}
</script>

<style scoped>

</style>
